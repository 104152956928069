import { useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';

function App() {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="flex flex-col w-full min-h-screen bg-black">
      <div
        className="w-full flex flex-col px-[40px]"
        style={{
          backgroundImage: `url('/svgs/background.svg')`,
        }}
      >
        <nav>
          <div className="flex justify-between">
            <div className="flex items-center">
              <img
                src="/svgs/logo.svg"
                alt="logo"
                className="w-[60px] md:w-[150px]"
              />
              <a
                href="https://ordinalswallet.com/collection/tap-$SORA"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white text-[30px] md:text-[50px] font-medium"
              >
                $SORA
              </a>
            </div>
            <div className="gap-[45px] items-center hidden lg:flex">
              <a
                href="https://x.com/SORA_BTC"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/svgs/twitter.svg" alt="twitter" />
              </a>
              <a
                href="https://t.me/SORA_ON_TAP"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/svgs/telegram.svg" alt="telegram" />
              </a>
              <a
                href="https://t.co/ymBRJERKTE"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/svgs/telegram.svg" alt="telegram" />
              </a>
            </div>
            <div className="lg:hidden flex items-center">
              <button onClick={() => setToggleMenu(!toggleMenu)}>
                <Bars3Icon className="text-white h-6" />
              </button>
            </div>
          </div>
          <div
            className={`fixed right-4 z-40 w-36 bg-black rounded-2xl ${
              toggleMenu ? 'border' : ''
            } overflow-hidden flex flex-col lg:hidden gap-12 duration-700 ${
              !toggleMenu ? 'h-0' : 'h-48'
            }`}
          >
            <div className="px-8 pt-8">
              <div className="flex flex-col gap-8 font-bold tracking-wider text-white">
                <a
                  href="https://x.com/SORA_BTC"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex justify-center items-center gap-4"
                >
                  <img src="/svgs/twitter.svg" alt="twitter" />
                </a>
                <a
                  href="https://t.me/SORA_ON_TAP"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex justify-center items-center gap-4"
                >
                  <img src="/svgs/telegram.svg" alt="telegram" />
                </a>
                <a
                  href="https://t.co/ymBRJERKTE"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex justify-center items-center gap-4"
                >
                  <img src="/svgs/telegram.svg" alt="telegram" />
                </a>
              </div>
            </div>
          </div>
        </nav>
        <div className="flex flex-col justify-center items-center mt-[195px]">
          <span className="relative text-white text-[40px] sm:text-[70px] md:text-[100px] lg:text-[200px] font-medium leading-normal">
            SORA
            <img
              src="/svgs/logo-400.svg"
              alt="mark"
              className="absolute -top-[34px] -right-[64px] md:-top-[68px] md:-right-[104px] lg:-top-[136px] lg:-right-[260px] w-20 md:w-32 lg:w-[324px]"
            />
          </span>
          <div className="text-white text-center text-2xl md:text-[36px] lg:text-[80px] font-medium">
            on <span className="text-[#BE6A00]">BITCOIN</span>
          </div>
          <a
            href="https://ordinalswallet.com/collection/tap-$SORA"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center justify-center mt-20 bg-gradient-to-r from-[#BE6A00] via-white to-[#FEBC72] transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-500 text-center rounded-l-full rounded-r-full w-[240px] h-[60px] md:w-[323px] md:h-[89px] p-px"
          >
            <div className="flex h-full w-full items-center justify-center bg-black rounded-r-full rounded-l-full">
              <span className="text-white text-2xl font-medium">BUY $SORA</span>
            </div>
          </a>
        </div>
        <div className="flex justify-end mt-32 md:mt-[360px] mb-8 md:mb-[48px]">
          <span className="text-white text-center md:text-left text-lg md:text-[30px] font-medium">
            Powered by Tap Protocol
          </span>
        </div>
      </div>
      <div
        className="flex flex-col w-full pt-[23px] bg-no-repeat bg-right-bottom bg-[length:200px_200px] md:bg-[length:800px_800px]"
        style={{
          backgroundImage: `url('/pngs/ellipse-1.png')`,
        }}
      >
        <div className="flex justify-center px-[40px] lg:px-[228px]">
          <span className="text-center text-[#AAAAAA] text-[30px] lg:text-[80px] mt-8 md:mt-0">
            By VC for the community
          </span>
        </div>
        <div className="flex flex-wrap justify-center px-[40px] lg:px-[228px] text-[#AAAAAA] mt-12 md:mt-[146px] md:text-[36px] gap-8 md:gap-[156px]">
          <span className="text-center">
            Deployed by Jason Fang,
            <br />
            founder of sora ventures a<br />
            lead investor in Tap Protocol
          </span>

          <span className="text-center">
            Created as a
            <br />
            demonstration but now
            <br />
            leading to $TAP adoption
          </span>
        </div>
        <div className="flex flex-col text-xl mt-12 md:mt-[200px] px-[40px] lg:px-[228px] text-[#AAAAAA] md:text-[36px]">
          <div className="flex flex-col">
            <span>Explore TAP </span>
            <div className="flex gap-6 items-center">
              <span>Ecosystem</span>
              <a
                href="https://trac.network/tap/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/svgs/arrow.svg"
                  className="w-[32px] md:w-[88px]"
                  alt="ecosystem"
                />
              </a>
            </div>
          </div>
          <div className="flex flex-col">
            <span>Built by </span>
            <div className="flex gap-6 items-center">
              <span>Tapstarter</span>
              <a
                href="https://www.tapstarter.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/svgs/arrow.svg"
                  className="w-[32px] md:w-[88px]"
                  alt="tapstarter"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="flex justify-center md:justify-end mt-[120px] mb-[50px] px-[64px]">
          <span className="text-[#CE9424] text-lg md:text-[30px]">
            Inspired by open AI
          </span>
        </div>
      </div>
    </div>
  );
}

export default App;
